import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";

import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";

import { getAnalytics, getLeftoverHistory } from "../../utils/apiRequests";
import InventoryTimeline from "./InventoryTimeline";

const getWeatherEmoji = (weather) => {
  const weatherStatusEmojis = {
    Snow: "❄️",
    Haze: "🌫️",
    Mist: "🌫️",
    Smoke: "💨",
    Fog: "😶‍🌫️",
    Drizzle: "🌧️",
    Clear: "☀️",
    Thunderstorm: "⛈️",
    Rain: "🌧️",
    Clouds: "☁️",
  };

  const weatherDetailEmojis = {
    "broken clouds": "🌥️",
    "few clouds": "🌤️",
    "scattered clouds": "🌤️",
  };
  return (
    weatherDetailEmojis[weather.weather_detail] ||
    weatherStatusEmojis[weather.weather_status]
  );
};

const handleDownloadInventory = async (storeName, weekRange, inventory) => {
  if (inventory) {
    const { startOfWeek, endOfWeek } = weekRange;

    const filteredInventory = inventory.filter((item) => {
      const itemDate = new Date(item.date + "Z");
      return (
        item.store_name === storeName &&
        itemDate >= startOfWeek &&
        itemDate <= endOfWeek
      );
    });

    const csv = filteredInventory.map((item) => {
      const timestampUTC = `${item.date}Z`;
      const date = new Date(timestampUTC);
      const line = `${item.store_name},${item.vendor_name},${
        item.product_name
      }, ${date.toLocaleDateString(undefined, {
        weekday: "long",
      })},${date.toLocaleDateString()},${date.toLocaleTimeString()},${
        item.leftovers
      }`;
      return line.split("\n").join("");
    });

    csv.unshift("Store,Vendor,Item,Day,Date,Time Out,Leftovers");

    const csvData = csv.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `inventory-history-${storeName}-${
      startOfWeek.toISOString().split("T")[0]
    }.csv`;
    a.click();
  }
};

const Analytics = () => {
  const {
    isLoading: isLoadingAnalytics,
    isError: isErrorAnalytics,
    data: analytics,
  } = useQuery("recentAnalytics", getAnalytics);

  const {
    isLoading: isLoadingInventory,
    isError: isErrorInventory,
    data: inventory,
  } = useQuery("inventoryHistory", getLeftoverHistory);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentStore, setCurrentStore] = useState(null);
  const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);

  const handleMenuClick = (event, store) => {
    setAnchorEl(event.currentTarget);
    setCurrentStore(store);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentStore(null);
  };

  const handleStoreChange = (event, newValue) => {
    setSelectedStoreIndex(newValue);
  };

  const calculateDateRange = () => {
    const currentDate = new Date();
    const currentDateUTC = new Date(
      currentDate.toISOString().replace("Z", "") + "Z"
    );

    // Find the most recent Monday (start of current week)
    const currentWeekStart = new Date(currentDateUTC);
    currentWeekStart.setHours(0, 0, 0, 0);
    while (currentWeekStart.getDay() !== 1) {
      currentWeekStart.setDate(currentWeekStart.getDate() - 1);
    }

    // Go back 2 more weeks for the start date
    const startDate = new Date(currentWeekStart);
    startDate.setDate(startDate.getDate() - 14); // 2 weeks

    // End date is the current date
    const endDate = new Date(currentDateUTC);
    endDate.setHours(23, 59, 59, 999);

    return { startDate, endDate };
  };

  const getPastWeeks = () => {
    const currentDate = new Date();
    const currentDateUTC = new Date(
      currentDate.toISOString().replace("Z", "") + "Z"
    );
    const weeks = [];

    // Find the most recent Monday
    const mostRecentMonday = new Date(currentDateUTC);
    mostRecentMonday.setHours(0, 0, 0, 0);
    while (mostRecentMonday.getDay() !== 1) {
      mostRecentMonday.setDate(mostRecentMonday.getDate() - 1);
    }

    // Current partial week (from most recent Monday to current time)
    const currentWeekEnd = new Date(currentDateUTC);
    currentWeekEnd.setHours(23, 59, 59, 999);
    weeks.push({
      startOfWeek: new Date(mostRecentMonday),
      endOfWeek: currentWeekEnd,
      isPartial: true,
    });

    // Last full week (Monday to Sunday)
    const lastWeekStart = new Date(mostRecentMonday);
    lastWeekStart.setDate(lastWeekStart.getDate() - 7);
    const lastWeekEnd = new Date(mostRecentMonday);
    lastWeekEnd.setDate(lastWeekEnd.getDate() - 1);
    lastWeekEnd.setHours(23, 59, 59, 999);
    weeks.push({
      startOfWeek: lastWeekStart,
      endOfWeek: lastWeekEnd,
      isPartial: false,
    });

    // Two weeks ago (Monday to Sunday)
    const twoWeeksAgoStart = new Date(lastWeekStart);
    twoWeeksAgoStart.setDate(twoWeeksAgoStart.getDate() - 7);
    const twoWeeksAgoEnd = new Date(lastWeekStart);
    twoWeeksAgoEnd.setDate(twoWeeksAgoEnd.getDate() - 1);
    twoWeeksAgoEnd.setHours(23, 59, 59, 999);
    weeks.push({
      startOfWeek: twoWeeksAgoStart,
      endOfWeek: twoWeeksAgoEnd,
      isPartial: false,
    });

    return weeks.reverse(); // Return in chronological order
  };

  const { startDate, endDate } = calculateDateRange();
  const pastWeeks = getPastWeeks();

  const uniqueStores = [...new Set(inventory?.map((item) => item.store_name))];
  const uniqueVendors = [
    ...new Set(inventory?.map((item) => item.vendor_name)),
  ].filter(Boolean);

  // Filter inventory data for selected store and merge with weather data
  const filteredInventory = inventory
    ?.filter((item) => item.store_name === uniqueStores[selectedStoreIndex])
    .map((item) => {
      const itemDate = item.date.split("T")[0];
      const weatherData = analytics?.find(
        (analytic) => analytic.created_at.split("T")[0] === itemDate
      );
      return {
        ...item,
        weather: weatherData
          ? {
              weather_status: weatherData.weather_status,
              weather_detail: weatherData.weather_detail,
              temp: weatherData.temp,
            }
          : null,
      };
    });

  return (
    <Grid xs={12} sx={{ mb: 5 }}>
      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h4" component="h1">
          Analytics
        </Typography>
      </Grid>

      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2">
          Inventory History
        </Typography>
      </Grid>

      <Grid xs={12} sx={{ mb: 2 }}>
        {isLoadingInventory && <Typography>Loading...</Typography>}
        {isErrorInventory && <Typography>Error loading inventory</Typography>}
        {uniqueStores?.length > 0 && (
          <>
            <Paper sx={{ mb: 2 }}>
              <Tabs
                value={selectedStoreIndex}
                onChange={handleStoreChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                {uniqueStores.map((store, index) => (
                  <Tab key={store} label={store} id={`store-tab-${index}`} />
                ))}
              </Tabs>
            </Paper>
            <ButtonGroup variant="contained" color="primary" sx={{ mb: 2 }}>
              <Button>{uniqueStores[selectedStoreIndex]}</Button>
              <Button
                color="primary"
                size="small"
                aria-controls="split-button-menu"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                aria-label="select week"
                aria-haspopup="menu"
                onClick={(e) =>
                  handleMenuClick(e, uniqueStores[selectedStoreIndex])
                }
              >
                ▼
              </Button>
            </ButtonGroup>
            <Menu
              id="split-button-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {pastWeeks.map((week, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleDownloadInventory(currentStore, week, inventory);
                    handleMenuClose();
                  }}
                >
                  {week.isPartial
                    ? `Current Week (Incomplete)`
                    : `${week.startOfWeek.toLocaleDateString()} - ${week.endOfWeek.toLocaleDateString()}`}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Grid>

      <Grid xs={12} sx={{ mb: 4 }}>
        {inventory &&
          uniqueVendors.map((vendor) => (
            <InventoryTimeline
              key={vendor}
              vendorName={vendor}
              inventoryData={filteredInventory}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
      </Grid>

      <Grid xs={12} sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2">
          Weather History
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="text.secondary"
          sx={{ my: 1 }}
        >
          Here's the weather history from the past few weeks. More coming soon!
        </Typography>
      </Grid>

      {isLoadingAnalytics && <Typography>Loading...</Typography>}
      {isErrorAnalytics && <Typography>Error loading analytics</Typography>}
      {analytics && (
        <Paper elevation={3}>
          <List>
            {analytics.map((analytic) => (
              <div key={analytic.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {new Date(analytic.created_at).toLocaleDateString(
                              undefined,
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item md={1} xs={2}>
                          <Typography variant="h4">
                            {analytic.temp
                              ? `${Math.round(analytic.temp)}°`
                              : "--"}
                          </Typography>
                        </Grid>
                        <Grid item md={11} xs={10}>
                          <Typography variant="h4" component="div">
                            {analytic.weather_status
                              ? getWeatherEmoji(analytic)
                              : ""}{" "}
                            {analytic.weather_status ?? "--"}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {analytic.weather_detail ?? "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Box sx={{ my: 2 }}>
                  <Divider variant="inset" component="li" />
                </Box>
              </div>
            ))}
          </List>
        </Paper>
      )}
    </Grid>
  );
};

Analytics.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Analytics;
