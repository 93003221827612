import React, { useState } from "react";
import { Box, Typography, Tooltip, tooltipClasses, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { VendorAvatar } from "../VendorAvatar";

const TimelineBar = styled("div")(
  ({ theme, height, isLeftover, isHighlighted }) => ({
    width: "8px",
    height: `${Math.abs(height)}px`,
    backgroundColor: isLeftover
      ? theme.palette.error.main
      : theme.palette.primary.main,
    margin: "0 2px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: isLeftover ? `translate(-50%, 0)` : `translate(-50%, -100%)`,
    transition: "all 0.2s ease-in-out",
    opacity: isHighlighted ? 1 : 0.3,
    "&:hover": {
      width: "12px",
      backgroundColor: isLeftover
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
      boxShadow: theme.shadows[3],
      opacity: 1,
    },
  })
);

const TimelineContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "16px",
});

const ProductInfoContainer = styled(Box)({
  width: "200px",
  minWidth: "200px",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
});

const ChipsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "4px",
  marginTop: "4px",
});

const StyledChip = styled(Chip)(({ theme }) => ({
  height: "20px",
  "& .MuiChip-label": {
    fontSize: "0.75rem",
    padding: "0 6px",
  },
}));

const BarContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "80px",
  position: "relative",
  backgroundColor: "#f5f5f5",
  backgroundImage: `
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 11px,
      rgba(0, 0, 0, 0.05) 11px,
      rgba(0, 0, 0, 0.05) 12px
    )
  `,
  "&::before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundImage: `
      repeating-linear-gradient(
        0deg,
        transparent,
        transparent 9px,
        rgba(0, 0, 0, 0.05) 9px,
        rgba(0, 0, 0, 0.05) 10px
      )
    `,
    pointerEvents: "none",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "1px",
    backgroundColor: "#999",
    top: "50%",
  },
});

const MondayMarker = styled(Box)(({ left }) => ({
  position: "absolute",
  left: `${left}px`,
  height: "100%",
  width: "1px",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  "&::after": {
    content: "'Mon'",
    position: "absolute",
    top: "100%",
    left: "-8px",
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

const BarWrapper = styled(Box)({
  position: "relative",
  width: "12px",
  height: "100%",
  "&:hover": {
    zIndex: 2,
  },
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5),
    fontSize: "0.875rem",
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[2],
    maxWidth: "none",
    "& .time-info": {
      color: theme.palette.text.secondary,
      fontSize: "0.75rem",
      marginTop: theme.spacing(0.5),
    },
    "& .count-info": {
      color: theme.palette.error.main,
      fontWeight: "bold",
      marginTop: theme.spacing(0.5),
    },
    "& .weather-info": {
      color: theme.palette.text.secondary,
      fontSize: "0.75rem",
      marginTop: theme.spacing(0.5),
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
    "&:before": {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const getWeatherEmoji = (weather) => {
  const weatherStatusEmojis = {
    Snow: "❄️",
    Haze: "🌫️",
    Mist: "🌫️",
    Smoke: "💨",
    Fog: "😶‍🌫️",
    Drizzle: "🌧️",
    Clear: "☀️",
    Thunderstorm: "⛈️",
    Rain: "🌧️",
    Clouds: "☁️",
  };

  const weatherDetailEmojis = {
    "broken clouds": "🌥️",
    "few clouds": "🌤️",
    "scattered clouds": "🌤️",
  };
  return (
    weatherDetailEmojis[weather.weather_detail] ||
    weatherStatusEmojis[weather.weather_status]
  );
};

const calculateDiscrepancies = (items, startDate, endDate) => {
  const discrepancies = {
    missingDates: [],
    excessLeftovers: [],
    earlySoldOut: [],
  };

  // Create a map of all dates in range
  const dateMap = new Map();
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split("T")[0];
    dateMap.set(dateStr, false);
  }

  // Check each inventory item
  items.forEach((item) => {
    const itemDate = new Date(item.date + "Z");
    const dateStr = itemDate.toISOString().split("T")[0];
    dateMap.set(dateStr, true);

    // Check for excess leftovers (more than 2)
    if (item.leftovers > 2) {
      discrepancies.excessLeftovers.push(dateStr);
    }

    // Check for early sellouts (before 12:30 PM local time)
    if (item.leftovers === 0) {
      const soldOutTime = new Date(item.date + "Z");

      const targetTime = new Date(soldOutTime);
      targetTime.setHours(12, 30, 0, 0); // 12:30 PM ET

      if (soldOutTime < targetTime) {
        discrepancies.earlySoldOut.push(dateStr);
      }
    }
  });

  // Find missing dates
  dateMap.forEach((hasData, dateStr) => {
    if (!hasData) {
      discrepancies.missingDates.push(dateStr);
    }
  });

  return discrepancies;
};

const calculateBarHeight = (date, leftovers) => {
  const inventoryTime = new Date(date + "Z");
  const targetTime = new Date(inventoryTime);
  targetTime.setHours(15, 0, 0, 0); // 3 PM ET

  // Calculate time-based height
  const timeDiff = Math.abs(inventoryTime - targetTime) / (1000 * 60 * 60); // hours
  const timeScale = Math.min(timeDiff / 5, 1); // Scale up to 5 hours difference
  const timeHeight = Math.max(timeScale * 25, 5); // Reduced from 50/10 to 25/5

  // Calculate leftover-based height independently
  if (leftovers > 0) {
    return Math.min(leftovers * 3.5, 35); // Reduced from 7/70 to 3.5/35
  }

  return timeHeight;
};

const findMondayPositions = (startDate, endDate) => {
  if (!startDate || !endDate) return [];

  let currentDate = new Date(startDate);
  const positions = [];
  let dayCount = 0;

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 1) {
      // Monday
      positions.push(dayCount * 12); // 12px per day
    }
    currentDate.setDate(currentDate.getDate() + 1);
    dayCount++;
  }

  return positions;
};

const formatDateTime = (date) => {
  const d = new Date(date + "Z");
  return {
    date: d.toLocaleDateString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    time: d.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
    }),
  };
};

const calculateDailySummary = (productGroups, startDate, endDate) => {
  const dailySummary = new Map();

  // Initialize all dates in range
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().split("T")[0];
    dailySummary.set(dateStr, { excess: 0, earlySoldOut: 0 });
  }

  // Aggregate data for each day
  Object.values(productGroups).forEach((items) => {
    items.forEach((item) => {
      const itemDate = new Date(item.date + "Z");
      const dateStr = itemDate.toISOString().split("T")[0];
      const summary = dailySummary.get(dateStr);
      if (summary) {
        const soldOutTime = new Date(item.date + "Z");
        const targetTime = new Date(soldOutTime);
        targetTime.setHours(12, 30, 0, 0);

        if (item.leftovers > 2) {
          summary.excess++;
        }
        if (item.leftovers === 0 && soldOutTime < targetTime) {
          summary.earlySoldOut++;
        }
      }
    });
  });

  // Calculate absolute maximum across both categories
  let absoluteMax = 0;
  dailySummary.forEach((summary) => {
    absoluteMax = Math.max(absoluteMax, summary.excess, summary.earlySoldOut);
  });

  return {
    data: Array.from(dailySummary.entries()).map(([date, data]) => ({
      date,
      ...data,
    })),
    absoluteMax,
  };
};

const SummaryBar = styled("div")(
  ({ theme, height, isExcess, isHighlighted }) => ({
    width: "8px",
    height: `${Math.abs(height)}px`,
    backgroundColor: isExcess
      ? theme.palette.error.main
      : theme.palette.primary.main,
    margin: "0 2px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: isExcess ? `translate(-50%, 0)` : `translate(-50%, -100%)`,
    transition: "all 0.2s ease-in-out",
    opacity: isHighlighted ? 1 : 0.3,
    zIndex: 1,
    "&:hover": {
      width: "12px",
      backgroundColor: isExcess
        ? theme.palette.error.dark
        : theme.palette.primary.dark,
      boxShadow: theme.shadows[3],
      opacity: 1,
      zIndex: 2,
    },
  })
);

const InventoryTimeline = ({
  inventoryData,
  vendorName,
  startDate,
  endDate,
}) => {
  const [highlightedDates, setHighlightedDates] = useState(new Set());

  // Group inventory by product and filter by date range
  const productGroups = inventoryData.reduce((groups, item) => {
    const itemDate = new Date(item.date + "Z");
    if (
      item.vendor_name === vendorName &&
      itemDate >= startDate &&
      itemDate <= endDate
    ) {
      if (!groups[item.product_name]) {
        groups[item.product_name] = [];
      }
      // Ensure weather data is properly structured
      const weatherData = item.weather
        ? {
            weather_status: item.weather.status,
            weather_detail: item.weather.description,
            temp: item.weather.temperature,
          }
        : null;

      groups[item.product_name].push({
        ...item,
        weather: weatherData,
      });
    }
    return groups;
  }, {});

  // Calculate total days for container width
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  const containerWidth = totalDays * 12; // 12px per day

  const handleChipMouseEnter = (dates) => {
    setHighlightedDates(new Set(dates));
  };

  const handleChipMouseLeave = () => {
    setHighlightedDates(new Set());
  };

  const dailySummary = calculateDailySummary(productGroups, startDate, endDate);

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <VendorAvatar vendor={{ id: vendorName, name: vendorName }} />
      </Box>

      {/* Summary Timeline */}
      <TimelineContainer>
        <ProductInfoContainer>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Daily Summary
          </Typography>
        </ProductInfoContainer>
        <BarContainer sx={{ width: `${containerWidth}px`, minWidth: "500px" }}>
          {findMondayPositions(startDate, endDate).map((left, index) => (
            <MondayMarker key={index} left={left} />
          ))}
          {dailySummary.data.map((day) => {
            const itemDate = new Date(day.date + "T12:00:00Z");
            const daysSinceStart = Math.floor(
              (itemDate - startDate) / (1000 * 60 * 60 * 24)
            );
            const isHighlighted =
              highlightedDates.size === 0 || highlightedDates.has(day.date);

            const formattedDate = formatDateTime(day.date + "T12:00:00").date;

            // Scale heights based on absolute maximum
            const MAX_BAR_HEIGHT = 40; // Maximum pixel height for bars
            const heightScale = MAX_BAR_HEIGHT / dailySummary.absoluteMax;

            const excessHeight = day.excess > 0 ? day.excess * heightScale : 0;
            const earlySoldOutHeight =
              day.earlySoldOut > 0 ? day.earlySoldOut * heightScale : 0;

            return (
              <React.Fragment key={day.date}>
                {day.excess > 0 && (
                  <BarWrapper
                    sx={{
                      position: "absolute",
                      left: `${daysSinceStart * 12}px`,
                    }}
                  >
                    <StyledTooltip
                      title={
                        <Box>
                          <Typography variant="subtitle2">
                            {formattedDate}
                          </Typography>
                          <Typography>
                            {day.excess} products with excess inventory
                          </Typography>
                        </Box>
                      }
                      arrow
                      placement="top"
                    >
                      <SummaryBar
                        height={excessHeight}
                        isExcess={true}
                        isHighlighted={isHighlighted}
                      />
                    </StyledTooltip>
                  </BarWrapper>
                )}
                {day.earlySoldOut > 0 && (
                  <BarWrapper
                    sx={{
                      position: "absolute",
                      left: `${daysSinceStart * 12}px`,
                    }}
                  >
                    <StyledTooltip
                      title={
                        <Box>
                          <Typography variant="subtitle2">
                            {formattedDate}
                          </Typography>
                          <Typography>
                            {day.earlySoldOut} products sold out early
                          </Typography>
                        </Box>
                      }
                      arrow
                      placement="bottom"
                    >
                      <SummaryBar
                        height={earlySoldOutHeight}
                        isExcess={false}
                        isHighlighted={isHighlighted}
                      />
                    </StyledTooltip>
                  </BarWrapper>
                )}
              </React.Fragment>
            );
          })}
        </BarContainer>
      </TimelineContainer>

      {/* Product Timelines */}
      {Object.entries(productGroups)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([productName, items]) => {
          const mondayPositions = findMondayPositions(startDate, endDate);
          const discrepancies = calculateDiscrepancies(
            items,
            startDate,
            endDate
          );
          const hasDiscrepancies =
            discrepancies.missingDates.length > 0 ||
            discrepancies.excessLeftovers.length > 0 ||
            discrepancies.earlySoldOut.length > 0;

          return (
            <TimelineContainer key={productName}>
              <ProductInfoContainer>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {productName}
                </Typography>
                {hasDiscrepancies && (
                  <ChipsContainer>
                    {discrepancies.earlySoldOut.length > 0 && (
                      <Tooltip title="Items sold out before 12:30 PM" arrow>
                        <StyledChip
                          label={`${discrepancies.earlySoldOut.length}× early sellout`}
                          color="primary"
                          size="small"
                          onMouseEnter={() =>
                            handleChipMouseEnter(discrepancies.earlySoldOut)
                          }
                          onMouseLeave={handleChipMouseLeave}
                        />
                      </Tooltip>
                    )}
                    {discrepancies.excessLeftovers.length > 0 && (
                      <Tooltip
                        title="Days with more than 2 items left over"
                        arrow
                      >
                        <StyledChip
                          label={`${discrepancies.excessLeftovers.length}× excess`}
                          color="error"
                          size="small"
                          onMouseEnter={() =>
                            handleChipMouseEnter(discrepancies.excessLeftovers)
                          }
                          onMouseLeave={handleChipMouseLeave}
                        />
                      </Tooltip>
                    )}
                    {discrepancies.missingDates.length > 0 && (
                      <Tooltip
                        title="Days with no inventory data recorded"
                        arrow
                      >
                        <StyledChip
                          label={`${discrepancies.missingDates.length}× missing`}
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[300],
                            color: (theme) => theme.palette.grey[800],
                          }}
                          size="small"
                          onMouseEnter={() =>
                            handleChipMouseEnter(discrepancies.missingDates)
                          }
                          onMouseLeave={handleChipMouseLeave}
                        />
                      </Tooltip>
                    )}
                  </ChipsContainer>
                )}
              </ProductInfoContainer>
              <BarContainer
                sx={{ width: `${containerWidth}px`, minWidth: "500px" }}
              >
                {mondayPositions.map((left, index) => (
                  <MondayMarker key={index} left={left} />
                ))}
                {items
                  .sort(
                    (a, b) => new Date(a.date + "Z") - new Date(b.date + "Z")
                  )
                  .map((item) => {
                    const height = calculateBarHeight(
                      item.date,
                      item.leftovers
                    );
                    const daysSinceStart = Math.floor(
                      (new Date(item.date + "Z") - startDate) /
                        (1000 * 60 * 60 * 24)
                    );
                    const itemDate = item.date.split("T")[0];
                    const isHighlighted =
                      highlightedDates.size === 0 ||
                      highlightedDates.has(itemDate);

                    return (
                      <BarWrapper
                        key={item.id}
                        sx={{
                          position: "absolute",
                          left: `${daysSinceStart * 12}px`,
                        }}
                      >
                        <StyledTooltip
                          title={
                            <Box>
                              <Typography variant="subtitle2">
                                {formatDateTime(item.date).date}
                              </Typography>
                              <Typography className="time-info">
                                {formatDateTime(item.date).time}
                              </Typography>
                              {item.weather && (
                                <Typography className="weather-info">
                                  {getWeatherEmoji(item.weather)}{" "}
                                  {item.weather.weather_detail ||
                                    item.weather.weather_status}
                                  {item.weather.temp &&
                                    ` • ${Math.round(item.weather.temp)}°F`}
                                </Typography>
                              )}
                              <Typography className="count-info">
                                {item.leftovers > 0
                                  ? `${item.leftovers} leftovers`
                                  : "Sold out"}
                              </Typography>
                            </Box>
                          }
                          arrow
                          placement={item.leftovers > 0 ? "top" : "bottom"}
                        >
                          <TimelineBar
                            height={height}
                            isLeftover={item.leftovers > 0}
                            data-count={item.leftovers || "0"}
                            isHighlighted={isHighlighted}
                          />
                        </StyledTooltip>
                      </BarWrapper>
                    );
                  })}
              </BarContainer>
            </TimelineContainer>
          );
        })}
    </Box>
  );
};

export default InventoryTimeline;
