import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import StoreList from "../StoreList";
import Vendors from "../Vendors";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Analytics from "../Analytics";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Dashboard = ({ auth }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container fixed>
      <Grid container>
        <Grid xs={12} sx={{ my: 4 }}>
          <Typography variant="h6" component="h1" className="py-3">
            Welcome, {auth.user.email}!
          </Typography>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Stores" />
              <Tab label="Products" />
              <Tab label="Analytics" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <StoreList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {auth.user.editor && <Vendors />}
            {!auth.user.editor && (
              <Typography variant="h6" component="h1" className="py-3">
                You are not authorized to view this content.
              </Typography>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Analytics />
          </TabPanel>
        </Box>
      </Grid>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Dashboard);
